<template>
  <div class="user row row--hover box box--default">
    <div
      class="row__data row__data--small row__data--image"
      @click="goToAccountDetails()"
    >
      <img v-if="userThumbnail" :src="userThumbnail" alt="" />
      <div v-else class="thumbnail thumbnail--user" :style="color">
        {{ parseInitials(user.first_name, user.last_name) }}
      </div>
    </div>
    <div
      class="row__data row__data--large row__data--name"
      @click="goToAccountDetails()"
    >
      {{ user.first_name }} {{ user.last_name }}
    </div>
    <div class="row__data row__data--medium">
      <a
        class="row__phone icon__phone"
        :href="user.phone ? `tel:${user.phone}` : 'javascript:void(0)'"
        :class="{ 'icon__phone--active': user.phone }"
      ></a>
      <a
        class="row__mail icon__mail"
        :href="user.email ? `mailto:${user.email}` : 'javascript:void(0)'"
        :class="{ 'icon__mail--active': user.email }"
      ></a>
    </div>
    <div
      class="row__data row__data--large row__data--team"
      
    >      
      <VSelect 
        v-if="teams"
        :options="teams"
        label="name"
        class="select select--default customer__dropdown"
        v-model="activeTeam"
        style="width: 100% !important;"
      /> 
       
      <span v-else>-</span>
    </div>
    <div
      class="row__data row__data--large row__data--team"
      @click="goToAccountDetails()"
    >
      <span v-if="user.company_name">{{ user.company_name }}</span>
      <span v-else>-</span>
    </div>
    <div
      class="row__data row__data--large row__data--address"
      @click="goToAccountDetails()"
    >
      {{ userAddress }}
    </div>
    <div
      class="row__data row__data--small row__data--zip"
      @click="goToAccountDetails()"
    >
      {{ userZip }}
    </div>
    <div
      class="row__data row__data--medium row__data--city"
      @click="goToAccountDetails()"
    >
      {{ userCity }}
    </div>
    <div
      class="row__data row__data--medium row__data--supervisor"
      @click="goToAccountDetails()"
    >
      {{ supervisor }}
    </div>
    <div
      class="row__data row__data--medium row__data--category"
      @click="goToAccountDetails()"
    >
      {{ saleCategory }}
    </div>
    <div
      class="row__data row__data--medium row__data--category"
      style="justify-content: center;"
    >
      <div v-if="user.approved" class="icon icon__active"></div> 
      <div v-else class="icon icon__inactive"></div> 
    </div>
    <div
      class="row__data row__data--small row__data--action "
      @click.self="showActionsDropdown = !showActionsDropdown"
    >
      <RowActionDropdown
        v-if="showActionsDropdown"
        @toggleDropdown="showActionsDropdown = $event"
        :onEdit="editUser"
        :onDelete="removeUser"
        :isActive = "user.approved"
        :onDisable = "disableTheUser"
        :onEnable = "enableTheUser"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RowActionDropdown from "@/components/RowActionDropdown";
import statics from "@/statics";
import { parseInitials } from "@/helpers/entityParsers"; 
export default {
  name: "User",

  components: {
    RowActionDropdown,
  },

  props: {
    user: {
      required: true,
      type: Object,
    },

    deleteUser: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      showActionsDropdown: false,
      parseInitials,
      activeTeam: {},
    };
  },

  computed: {
    ...mapGetters(["users", "saleCategories"]),

    saleCategory() {
      return this.user.sales_category
        ? this.user.sales_category.name_en || statics.empty
        : statics.empty;
    },

    supervisor() {
      return this.user.supervisor
        ? this.user.supervisor.first_name || statics.empty
        : statics.empty;
    },

    userThumbnail() {
      return this.user.photo ? this.user.photo.thumbnail : null;
    },

    userAddress() {
      return this.user.user_addresses.length
        ? `${this.user.user_addresses[0].street || statics.empty} ${this.user
            .user_addresses[0].number || statics.empty}`
        : statics.empty;
    },

    userZip() {
      return this.user.user_addresses.length
        ? this.user.user_addresses[0].zip || statics.empty
        : statics.empty;
    },

    userCity() {
      return this.user.user_addresses.length
        ? this.user.user_addresses[0].city || statics.empty
        : statics.empty;
    },

    color() {
      return this.user.color
        ? { background: this.user.color }
        : { background: "#7f8fa4" };
    },

    teams() {
      if (this.user.teams.length) {

        return this.user.teams.map((team) => ({
          id: team.id,
          name: team.name,
        }));
      }
      return null;
    },
  },

  methods: {
    goToAccountDetails() {
      this.$router.push({
        name: "AccountDetails",
        params: {
          entity: "user",
          id: this.user.id,
        },
      });
    },

    editUser() {
      this.$store.commit("setUserToEdit", this.user.id);
      this.$store.commit("setIsEdit", true);
      this.$store.commit("setShowAddUserModal", true);
    },

    removeUser() {
      this.deleteUser({
        display: true,
        message: `'${this.user.first_name}'`,
        action: this.$store._actions.deleteUser,
        id: this.user.id,
      });
    },

    disableTheUser(){
      this.$store.dispatch("disableUser", this.user.id)
    },
    enableTheUser(){
      this.$store.dispatch("enableUser", this.user.id)
    },   
  },

  created(){
    if (this.teams) {
      const { id, name } = this.teams[0];
      this.activeTeam = {
        id,
        name,
      };
    }
  }
};
</script>
